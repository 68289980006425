import React from 'react'
import styles from './DropdownSelector.module.css'
import ToggleIcon from '../icons/Angle'
import RadioButton from './RadioButton'
import SearchIcon from '../icons/SearchIcon'
import Close from '../icons/Close'
import Catering from '../icons/Catering'

interface Props {
  heading: string
  subHeading: string
  buttonText: string
  errorHeading: string
  content: DropdownContent[]
  onSelect: (id: string) => void
}

interface DropdownContent {
  heading: string
  listItems: { label: string; id: string; }[]
}

function DropdownSelector({ content, heading, subHeading, errorHeading, buttonText, onSelect }: Props) {
  const [activeDropdown, setActiveDropdown] = React.useState(true)
  const [activeCategory, setActiveCategory] = React.useState<DropdownContent | null>(null)
  const [selected, setSelected] = React.useState<string>('')
  const [searchCriteria, setSearchCriteria] = React.useState('')
  const [searchResult, setSearchResult] = React.useState<any[]>([])

  const handleItemClick = (item: DropdownContent) => {
    if (activeCategory === item) {
      setActiveCategory(null)
    } else {
      setActiveCategory(item)
    }
  }

  const handleSelect = () => { 
    setActiveDropdown(false)
    setActiveCategory(null)
    onSelect(selected)
  }
  
  React.useEffect(() => {
    setActiveDropdown(true)
    const filteredResults = content.map(category => ({
      heading: category.heading,
      listItems: category.listItems.filter(item => 
        item.label.toLowerCase().includes(searchCriteria.toLowerCase())
      )
    })).filter(category => category.listItems.length > 0)
    
    setSearchResult(filteredResults)
  }, [searchCriteria, content])

  return (
    <>
      <div className={styles.dropdownContainer}>
        <h3 className={styles.dropdownHeading}>{heading}</h3>
        <div className={styles.searchField}>
          <input
            placeholder='Sök'
            className={styles.searchFilter}
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
          />
          <div className={styles.searchFieldIcons} >
            {searchCriteria.length > 0 && 
              <span onClick={() => setSearchCriteria('')}>
                <Close className={styles.closeIcon} color={'#CF2E05'} />
              </span>}
            <span onClick={() => setSearchCriteria(searchCriteria)}>
              <SearchIcon className={styles.searchIcon} backgroundColor='var(--red)' color='#ffff'/>
            </span>
          </div>
        </div>
        <div className={styles.dropdownContent} style={{ marginBottom: (!activeDropdown && searchCriteria.length === 0) || (activeDropdown && searchCriteria.length > 0 && searchResult.length === 0) ? '0' : '4.5rem' }}>
          <button className={styles.dropdownButton} onClick={() => setActiveDropdown(!activeDropdown)} style={{ display: searchCriteria.length > 0 && searchResult.length === 0 ? 'none' : 'flex' }}>
            {subHeading}
            <span className={activeDropdown ? styles.activeToggleDropdownIcon : styles.toggleDropdownIcon}>
              <ToggleIcon className={styles.toggleIcon} direction='down' color='#d10000' />
            </span>
          </button>
          {activeDropdown && (
            <>
              <div className={styles.listItems}>
                {searchCriteria.length > 0 && searchResult.length === 0 ? (
                  <div className={styles.noResults}>
                    <Catering className={styles.noResultsIcon} />
                    <h3 className={styles.noResultsHeading}>{errorHeading}</h3>
                    <p>Kolla om orden är rättstavade eller försök med andra sökord.</p>
                  </div>
                ) : ( 
                  (searchResult.length > 0 ? searchResult : content)?.map((item: DropdownContent, index) => (
                    <div key={index}>
                      <div className={styles.listItem} onClick={() => handleItemClick(item)}>
                        <span style={{ fontWeight: activeCategory === item ? '700' : '400' }}>
                          {item.heading} ({item.listItems.length})
                        </span>
                        <span className={activeCategory === item ? styles.activeToggleDropdownIcon : styles.toggleDropdownIcon}>
                          <ToggleIcon className={styles.toggleIcon} direction='down' color='#d10000' />
                        </span>
                      </div>
                      {activeCategory === item && (
                        <ul>
                          {item.listItems.map((listItem, index) => (
                            <li key={listItem.id} className={styles.item}>
                              <RadioButton 
                                id={`productOption-${index}`}
                                onChange={() => setSelected(listItem.id)}>
                                {listItem.label}
                              </RadioButton> 
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.buttonWrapper}
          style={{ display: (!activeDropdown && searchCriteria.length === 0) || (activeDropdown && searchCriteria.length > 0 && searchResult.length === 0) ? 'none' : 'flex' }}
        >
          <button  
            className={styles.button} 
            onClick={handleSelect}>
            {buttonText}
          </button>
        </div>
      </div>
    </>
  )
}

export default DropdownSelector