import React from 'react'

type IconT = {
  className?: string
  color?: string
}

function Plus({ className, color = 'currentColor' }: IconT) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.8258 11.2455H12.7678V6.1935C12.7678 6.08775 12.6808 6 12.5743 6H11.4666C11.3548 6 11.2701 6.08475 11.2701 6.1965V11.2455H6.74982C6.67932 11.2455 6.61407 11.2853 6.58257 11.349L6.02007 12.474C5.95782 12.5985 6.04782 12.7455 6.18732 12.7455H11.2701V17.8065C11.2701 17.913 11.3571 18 11.4636 18L12.5743 17.997C12.6831 18 12.7708 17.913 12.7678 17.8035V12.7455H17.8258C17.9338 12.7455 18.0201 12.6585 18.0201 12.5512V11.439C18.0201 11.3325 17.9338 11.2455 17.8258 11.2455Z" fill={color}/>
    </svg>
  )
}

export default Plus