export function formatVariant (variantName: string, shouldTrim: boolean = true) {
  let split: string[]
  if (variantName && variantName.length > 0) {
    if (variantName.indexOf('%') > -1) {
      split = variantName.replaceAll('%:', ': ')
        .split('%,')
    } else {
      split = variantName.split(',')
    }
    return shouldTrim ? split.map(i => i.trim()) : split
  }
}