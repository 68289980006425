import React from 'react'
import styles from './TextArea.module.css'

interface Props {
  value: string
  placeholder: string
  setComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

function TextArea({ value, placeholder, setComment }: Props) {
  return (
    <textarea 
      placeholder={placeholder}
      className={styles.commentInput} 
      value={value} 
      onChange={setComment}>

    </textarea>
  )
}

export default TextArea