import React from 'react'
import { Dispatch } from '../../models'
import styles from '../CateringOrderItem.module.css'
import Banner from '../Banner'
import { getFormattedPrice } from '../../helpers/formatPrice'
import DropdownSelector from '../DropdownSelector'
import { connect } from 'react-redux'
import { Product, Variant } from '../../types/Product'
import logError from '../../utils/logging'
import loader from '../../icons/loading.gif'
import CloseIcon from '../../icons/Close'
import TextArea from '../TextArea'
import Variants from '../Variants'
import CountStepper from '../CountStepper'
import { getTaxFactor } from '../../helpers/getTaxFactor'
import { OrderUpdateError } from '../../types/Order'
import { emptyGuid } from '../../constants'

export interface Category {
  categoryName: string
  products: Product[]
}

interface Props {
  isCardPayment: boolean
  error: OrderUpdateError
  originalTotalPrice: number
  storeId: string
  setProductToAdd: (newOrderItem: {productId: string, variantId: string, quantity: number, orderItemComment: string, price: number} | null) => void
  getCateringProducts: (storeId: string) => Promise<Category[] | undefined>
  getCateringProduct: (productId: string) => Promise<Product | undefined>
}

function AddProduct ({ isCardPayment, error, originalTotalPrice, storeId, setProductToAdd, getCateringProducts, getCateringProduct }: Props) {
  const [categories, setCategories] = React.useState<{ heading: string; listItems: { label: string; id: string; }[] }[]>([])
  const [loadingCategories, setLoadingCategories] = React.useState<boolean>(true)
  const [loadingProduct, setLoadingProduct] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState<string>('')
  const [quantity, setQuantity] = React.useState<number>(1)
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null)
  const [variantId, setVariantId] = React.useState<string>('')
  const [vat, setVat] = React.useState<number>(0)
  const [price, setPrice] = React.useState<number>(0)
  
  React.useEffect(() => {
    let isMounted = true
    const fetchProducts = async () => {
      try {
        const res = await getCateringProducts(storeId)
        if (isMounted && res) {
          const formattedCategories = res.map(category => ({
            heading: category.categoryName,
            listItems: category.products.map(product => ({
              label: product.name,
              id: product.id
            }))
          }))
          setCategories(formattedCategories)
        }
      } catch (error) {
        if (isMounted) {
          logError(error as Error)
        }
      } finally {
        setLoadingCategories(false)
      }
    }
    fetchProducts()
    return () => {
      isMounted = false
    }
  } ,[])

  React.useEffect(() => {
    if (selectedProduct) {
      setProductToAdd({ 
        productId: selectedProduct.id, 
        variantId: variantId === "" ? emptyGuid : variantId,
        quantity, 
        orderItemComment: comment, 
        price: selectedProduct.price / 100 
      })
    }
  }, [selectedProduct, quantity,variantId, comment])

  const handleSelectedProduct = async (productId: string) => {
    setLoadingProduct(true)
    setSelectedProduct(null)
    setProductToAdd(null)
    setComment('')
    setQuantity(1)
    try {
      const res = await getCateringProduct(productId)
      if (res) {
        setSelectedProduct(res)
        setPrice(res.price / 100)
        setVat(res.price / 100 - (res.price / 100 * getTaxFactor(res.taxCode)))
      }
    } catch (error) {
      logError(error as Error)
    } finally {
      setLoadingProduct(false)
    }
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0
    setQuantity(value)
  }

  const onMinus = () => {
    if(quantity > 0) {
      setQuantity(prev => prev - 1)
    }
  }

  const onPlus = () => {
    setQuantity(prev => prev  + 1)
  }

  const handleVariantChange = (variant: Variant) => {
    setVariantId(variant.id)
    const variantPrice = variant.price / 100
    const hasVariant = variantPrice !== 0
    const newGrossPrice = hasVariant ? variantPrice : selectedProduct?.price as number / 100
    const newVat = newGrossPrice - (newGrossPrice * getTaxFactor(selectedProduct?.taxCode as number))
    setPrice(newGrossPrice)
    setVat(newVat)
  }
  
  return (
    <>
      {loadingCategories ? (
        <div className={styles.loaderBox}><img src={loader} className={styles.loader} alt="loading" /></div>
      ) : (
        <>
          <div className={styles.bannerWrapper}>
            {(isCardPayment || error.errorType === 'General') && (
              <Banner
                theme={error.isError ? 'warning' : 'info'}>
                <span> {error.isError ? error.errorText : `Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade beloppet på ${getFormattedPrice(originalTotalPrice)}`}</span>
              </Banner>
            )}
          </div>
          <div className={styles.addProductWrapper}>
            <h4 className={styles.updateHeading}>Välj produkt du vill lägga till</h4>
            <div className={styles.dropdownWrapper}>
              <DropdownSelector 
                heading='Lägg till produkt'
                subHeading='Alla kategorier'
                buttonText='Lägg till'
                errorHeading='Hittade inga produkter'
                content={categories}
                onSelect={handleSelectedProduct}
              />
            </div>
            {loadingProduct &&  <div className={styles.loaderBox}><img src={loader} className={styles.loader} alt="loading" /></div>}
            {selectedProduct && (
              <div className={styles.selectedProduct}>
                <button className={styles.productLabel} onClick={() => {setSelectedProduct(null); setProductToAdd(null) }}>
                  {selectedProduct?.name} 
                  <span className={styles.removeProductIcon}>
                    <CloseIcon className={styles.closeIcon} color='#ffff' />
                  </span>
                </button>
                <h3 className={styles.productHeading}>{selectedProduct?.name}</h3>
                {selectedProduct?.variantCategories && selectedProduct.variantCategories.length && (
                  <Variants product={selectedProduct} predefined={false} onSelectVariant={handleVariantChange} />
                )}
                {selectedProduct?.allowComment && (
                  <>
                    <h4 className={styles.optionHeading}>Meddelande</h4>
                    <div className={styles.comment}> 
                      <TextArea 
                        value={comment} 
                        placeholder='Skriv meddelande (valfritt)' 
                        setComment={e => setComment(e.target.value)} 
                      />
                    </div>
                  </>
                )}
                <div>
                  <div className={styles.productUpdateAmount}>
                    <div>
                      <span className={styles.productAmountTitle}>Antal</span>
                    </div>        
                    <div className={styles.productStepper}>
                      <span className={styles.productItemPrice}>{getFormattedPrice(selectedProduct?.price as number / 100)}/st</span>
                      <CountStepper
                        count={quantity} 
                        minValue={1}
                        error={error.errorType === 'Payment'}
                        onMinus={onMinus} 
                        onPlus={onPlus} 
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleQuantityChange(e)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQuantityChange(e)}  />
                    </div>
                  </div>
                  <div className={styles.productTotal}>
                    <div className={styles.productTotalDetail}>
                      <span className={styles.totalPrice}>Totalt</span>
                      <span className={styles.numberOfArticles}>{quantity === 1 ? `${quantity} artikel ` : `${quantity} artiklar`}</span>
                    </div>
                    <div className={styles.productTotalDetail}>
                      <span className={styles.totalPrice}>
                        {getFormattedPrice((price * quantity))}
                      </span>
                      <span className={styles.totalVat}>Moms: {getFormattedPrice(vat * quantity)}</span>
                    </div>
                  </div>
                </div>
              </div>   
            )}
          </div>
        </>
      )}
    </>
  )
}

function mapDispatch(dispatch: Dispatch) {
  return {
    getCateringProducts: dispatch.order.getCateringProducts,
    getCateringProduct: dispatch.order.getCateringProduct
  }
}

export default connect(null, mapDispatch)(AddProduct)