import React from 'react'
import styles from './Input.module.css'

interface Input {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  id?: string
  type?: string
  placeholder?: string
  error?: boolean,
  errorText?: string,
  autoComplete?: string,
  required?: boolean,
  inputMode?: React.InputHTMLAttributes<HTMLInputElement>['inputMode'];
}

function Input({ type ='text', value, onChange, placeholder, id, error = false, onBlur, errorText, autoComplete = 'false', required, inputMode }: Input) {

  return (
    <div className={styles.inputContainer}>
      <input 
        onBlur={onBlur} 
        id={id} 
        placeholder={placeholder} 
        className={error ? styles.inputError : styles.input} 
        type={type} value={value}
        onChange={onChange} 
        autoComplete={autoComplete} 
        required={required}
        inputMode={inputMode} 
        aria-invalid={error} 
        aria-describedby={error ? `${id}-error` : undefined}
      />
      <label htmlFor={id} className={styles.label} aria-hidden={true}>{placeholder}</label>
      {error === true && errorText && (
        <>
          <div id={`${id}-error`} className={styles.errorText} role='alert'>
            {errorText}
          </div>
        </>
      )}
    </div>
  )
}

export default Input