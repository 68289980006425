import React from 'react'
import styles from '../CateringOrderItem.module.css'
import DatePicker from '../DatePicker'
import { OpenHour } from '../../types/Store'
import { getHourlySlots } from '../../helpers/getHourlySlots'
import Banner from '../Banner'
import { format, getDay } from 'date-fns'

interface Props {
  deliveryDate: string
  deliveryTimeSlot?: string
  dayOfWeek: string
  openHours: OpenHour[]
  calenderIsVisible: boolean
  onUpdateDeliveryDate: (date: Date) => void
  onUpdateDeliveryTimeSlot: (timeSlot: string) => void
}

function UpdateDelivery ({ deliveryDate, openHours, onUpdateDeliveryDate, onUpdateDeliveryTimeSlot, deliveryTimeSlot, dayOfWeek, calenderIsVisible }: Props) {
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = React.useState(new Date(deliveryDate))
  const [currentMonth] = React.useState<Date>(updatedDeliveryDate)
  const [timeSlots, setTimeSlots] = React.useState<string[]>([])
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<string | undefined>(deliveryTimeSlot)

  React.useEffect(() => {
    const dayNumber = getDay(updatedDeliveryDate)
    const deliveryTimeSlots = getHourlySlots(dayNumber, openHours)
    setTimeSlots(deliveryTimeSlots)
  }, [updatedDeliveryDate])

  const handleDateSelected = (dateObj: Date) => {
    if (updatedDeliveryDate.getTime() !== dateObj.getTime()) {
      setUpdatedDeliveryDate(new Date(dateObj))
      onUpdateDeliveryDate(dateObj)
    }
  }

  const handleTimeSlotSelected = (timeSlot: string) => {
    setSelectedTimeSlot(timeSlot)
    onUpdateDeliveryTimeSlot(timeSlot)
  }

  return (
    <div className={styles.updateWrapper}>
      <h4 className={styles.updateHeading}>Ändra dag eller tid för leverans</h4>
      <div className={styles.deliveryUpdateContent}>
        <p className={styles.pickDelivery}>Välj leveransdatum</p>
        <Banner theme="info">
          <div className={styles.deliveryUpdateBanner}>
            <span>
              {deliveryTimeSlot
                ? 'Leveransdag och tid: '
                : 'Leveransdag: '}
              {dayOfWeek}, {format(updatedDeliveryDate, 'yyyy-MM-dd')}
              {deliveryTimeSlot ? `, ${selectedTimeSlot}` : ''}
            </span>
          </div>
        </Banner>
        <div className={styles.datePickerWrapper}>
          <DatePicker
            isActive={calenderIsVisible}
            firstDayOfWeek={1}
            onDateSelected={({ date }) => handleDateSelected(date)}
            selected={updatedDeliveryDate}
            minDate={new Date()}
            position='relative'
            date={currentMonth}
            hideButton={true}
          />
        </div>
        <p className={styles.pickDelivery}>Välj tid för upphämtning</p>
        <div className={styles.timeSlotButtons}>
          {openHours && timeSlots.map((timeSlot, i) => (
            <button
              key={i}
              className={timeSlot === selectedTimeSlot ? styles.timeSlotButtonSelected : styles.timeSlotButton}
              onClick={() => handleTimeSlotSelected(timeSlot)}>
              {timeSlot}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UpdateDelivery