/* eslint-disable indent */
import React from 'react'
import styles from './OrderChanges.module.css'
import AngleIcon from '../icons/Angle'
import Banner from './Banner'
import type { OrderChange, OrderHistoryOperation } from '../types/Order'
import { format } from 'date-fns'
import { formatVariant } from '../helpers/formatVariant'

interface Props {
  orderHistory: OrderChange[]
  storeComment?: string
}

function OrderChange ({ orderHistory, storeComment }: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false)

  interface NewProduct {
    productId: string;
    variantId: string;
    quantity: number;
    orderItemComment: string;
  }

  const createNewProductString = (displayName: string, extraInfo: string): string => {
    const orderItem: NewProduct = JSON.parse(extraInfo)
    const { quantity, orderItemComment } = orderItem
    return `${quantity} st ${formatVariant(displayName, false)} ${orderItemComment ? ', Meddelande: ' + orderItemComment : ''}`
  }

  const getChangeTexts = React.useCallback((changes: OrderHistoryOperation[]) => {
    const text: string[] = []

    const groupedChanges = changes.reduce<Record<string, OrderHistoryOperation[]>>((acc, change) => {
      // if no group id exists, group by keyword 'other'
      return groupChanges(acc, change, change.groupId ? change.groupId : 'other')
    }, {})

    // for each group calculate the total old and new value and get the min and max values from the group.
    const result = Object.entries(groupedChanges).flatMap(([key, group]) => {
      if (key !== 'other') {
        const totalOldValue = group.reduce((sum, item) => sum + parseInt(item.oldValue as string), 0)
        const totalNewValue = group.reduce((sum, item) => sum + parseInt(item.newValue), 0)

        const increase = totalNewValue > totalOldValue

        return {
          ...group[0],
          ...getMinAndMaxValues(group, increase)
        }
      } else {
        return group
      }
    })

    result.forEach((change) => {
      const getVariantText = () => {
        const variants = change.variantName ? formatVariant(change.variantName) : []
        return variants && variants.length > 0 ? `, ${variants.join(', ')}` : ''
      }

      const changeTexts = {
        UpdateItemQuantity: () => {
          const variantText = getVariantText()
          return `${change.name}${variantText} ändrades från ${change.calculatedOldValue} st till ${change.calculatedNewValue} st.`
        },
        UpdateItemVariant: () => {
          const variantText = getVariantText()
          return `${change.name}${variantText} ändrade variant från ${formatVariant(change.oldValueDisplayName, false)} till ${formatVariant(change.newValueDisplayName, false)}.`
        },
        UpdateStoreComment: () => {
          const prefix = change.newValue === '' 
          ? 'Butikskommentar togs bort.' 
          : storeComment && change.oldValue 
            ? 'Butikskommentar ändrades till: ' 
            : 'Butikskommentar lades till: '
        return `${prefix}${change.newValue}`
      },
        UpdateDeliveryDate: () => `Leveransdatum ändrades från ${change.oldValue} till ${change.newValue}`,
        UpdateDeliveryTimeSlot: () => `Leveranstid ändrades från ${change.oldValue} till ${change.newValue}`,
        UpdateCustomerName: () => `Namn på ordern ändrades från ${change.oldValue} till ${change.newValue}`,
        UpdateCustomerEmail: () => `E-postadress på ordern ändrades från ${change.oldValue} till ${change.newValue}`,
        UpdateCustomerPhoneNumber: () => `Telefonnummer på ordern ändrades från ${change.oldValue} till ${change.newValue}`,
        AddProduct: () => {
          const newProductString = createNewProductString(change.newValueDisplayName, change.newValue, )
          return `Ny produkt lades till i order: ${newProductString}`
        }
      }

      const changeText = changeTexts[change.operationType as keyof typeof changeTexts]
      if (changeText) {
        text.push(changeText())
      }
    })

    return text
  }, [])

  function groupChanges (acc: Record<string, OrderHistoryOperation[]>, change: OrderHistoryOperation, key: string): Record<string, OrderHistoryOperation[]> {
    // if key doesn't exist in accumulated values, create it and set it to an empty array
    if (!acc[key]) {
      acc[key] = []
    }
    // push change to group with associated key.
    acc[key].push(change)
    return acc
  }

  function getMinAndMaxValues (group: OrderHistoryOperation[], increase: boolean) {
    // if increase is true, get the calculatedOldValue as min value and calculatedNewValue as max value
    const minKey = increase ? 'calculatedOldValue' : 'calculatedNewValue'
    const maxKey = increase ? 'calculatedNewValue' : 'calculatedOldValue'

    return {
      [minKey]: Math.min(...group.map(operation => operation[minKey] as number)),
      [maxKey]: Math.max(...group.map(operation => operation[maxKey] as number))
    }
  }

  const getOrderChangeHeading = (orderHistory: OrderChange) => {
    const lastOrderDate = new Date(orderHistory.date)
    const formattedDate = format(lastOrderDate, 'yyyy-MM-dd')
    const formattedTime = format(lastOrderDate, 'HH:mm')
    return `Ordern ändrades: ${formattedDate}, kl ${formattedTime} av ${orderHistory.updatedByName}`
  }

  const itemsToView = React.useMemo(() => {
    const items = orderHistory.slice(0, isExpanded ? undefined : 1)
    return items.map(item => {
      return {
        id: item.id,
        heading: getOrderChangeHeading(item),
        changeTexts: getChangeTexts(item.operations)
      }
    })
  }, [isExpanded, orderHistory])

  return (
    <div className={styles.orderChangeWrapper}>
      <div className={styles.headingWrapper}>
        <h4 className={styles.heading}>Tidigare ändringar</h4>
        {orderHistory.length > 1 && (
          <div onClick={() => setIsExpanded(!isExpanded)} className={styles.showMoreLink}>
            <span className={styles.showMoreLinkText}>Visa tidigare ändringar ({orderHistory?.length - 1} st)</span>
            <span className={isExpanded ? styles.activeExpanded : styles.angleIcon}>
              <AngleIcon className={styles.icon} direction='down' color='currentColor' />
            </span>
          </div>

        )}
      </div>
      <div className={styles.bannerWrapper}>
        {itemsToView.map(item => {
          return (
            <Banner theme="success" key={item.id}>
              <div>
                <span>
                  {item.heading}
                </span>
                <ul className={styles.updatedItemList}>
                  {item.changeTexts.map((text, index) => {
                    return (
                      <li key={index} className={styles.updatedItem}>
                        {text}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Banner>
          )
        })}
      </div>
    </div>
  )
}

export default OrderChange