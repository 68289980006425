import React from 'react'
import styles from '../CateringOrderItem.module.css'
import { OrderItem, OrderUpdateError } from '../../types/Order'
import Banner from '../Banner'
import { getFormattedPrice } from '../../helpers/formatPrice'
import { connect } from 'react-redux'
import { Dispatch } from '../../models'
import logError from '../../utils/logging'
import CountStepper from '../CountStepper'
import Variants from '../Variants'
import loader from '../../icons/loading.gif'
import { Product, Variant } from '../../types/Product'

interface Props {
  orderItem: OrderItem
  originalTotalPrice: number
  isCardPayment: boolean
  error: OrderUpdateError
  getCateringProduct: (productId: string) => Promise<Product | undefined>
  onAmountChange?: (count: number | string, id: string) => void
  onSelectVariant: (orderItemId: string, productPrice: number, taxCode: number, variant: Variant) => void
}

function UpdateProduct ({ orderItem, originalTotalPrice, isCardPayment, error, onSelectVariant, getCateringProduct, onAmountChange }: Props) {
  const [productToEdit, setProductToEdit] = React.useState<Product>({} as Product)
  const [count, setCount] = React.useState<number>(orderItem?.amount)
  const [loading, setLoading] = React.useState<boolean>(true)

  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0
    setCount(value)
    onAmountChange && onAmountChange(value, orderItem.id)
  }

  const onMinus = () => {
    if(count > 0) {
      setCount(prev => prev - 1)
      onAmountChange && onAmountChange(count - 1, orderItem.id)
    }
  }

  const onPlus = () => {
    setCount(prev => prev  + 1)
    onAmountChange && onAmountChange(count + 1, orderItem.id)
  }

  React.useEffect(() => { 
    let isMounted = true
    const fetchProduct = async () => {
      try {
        const res = await getCateringProduct(orderItem.productId)
        if (isMounted && res) {
          setProductToEdit(res)
          setLoading(false)
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false)
          logError(error as Error)
        }
      }
    }
    fetchProduct()
    return () => {
      isMounted = false
    }
  }, [orderItem.id])

  const handleSelectedVariant = (variant: Variant) => { 
    onSelectVariant(orderItem.id, productToEdit.price, productToEdit.taxCode, variant )
  }


  return (
    <>
      {loading ? (
        <div className={styles.loaderBox}><img src={loader} className={styles.loader} alt="loading" /></div>
      ) : (
        <> 
          <div className={styles.bannerWrapper}>
            {(isCardPayment || error.errorType === 'General') && (
              <Banner
                theme={error.isError ? 'warning' : 'info'}>
                <span> {error.isError ? error.errorText : `Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade beloppet på ${getFormattedPrice(originalTotalPrice)}`}</span>
              </Banner>
            )}
          </div>
          <div className={styles.productUpdate}>
            <div>
              <h4 className={styles.orderHeading}>{productToEdit?.name}</h4>
              {orderItem.variantId && productToEdit?.variantCategories && productToEdit?.variantCategories.length > 0 && (
                <Variants product={productToEdit} variantId={orderItem.variantId} predefined={true} onSelectVariant={(variant: Variant) => handleSelectedVariant(variant)} />
              )} 
            </div>

            <div className={styles.productUpdateSummary}>
              <div className={styles.productUpdateAmount}>
                <div>
                  <span className={styles.productAmountTitle}>Antal</span>
                </div>        
                <div className={styles.productStepper}>
                  <span className={styles.productItemPrice}>{getFormattedPrice(orderItem.priceGross)}/st</span>
                  <CountStepper
                    count={count} 
                    minValue={0}
                    error={error.isError}
                    onMinus={onMinus} 
                    onPlus={onPlus} 
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCount(e)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCount(e)}  />
                </div>
              </div>
              <div className={styles.productTotal}>
                <div className={styles.productTotalDetail}>
                  <span className={styles.totalPrice}>Totalt</span>
                  <span className={styles.numberOfArticles}>{count === 1 ? `${count} artikel ` : `${count} artiklar`}</span>
                </div>
                <div className={styles.productTotalDetail}>
                  <span className={styles.totalPrice}>
                    {getFormattedPrice(Math.round(orderItem.priceGross * count * 100) * 0.01)}
                  </span>
                  <span className={styles.totalVat}>Moms: {getFormattedPrice(orderItem.vatSubTotal * count)}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

function mapDispatch (dispatch: Dispatch) {
  return {
    getCateringProduct: dispatch.order.getCateringProduct,
  }
}

export default connect(null, mapDispatch)(UpdateProduct)