import React from 'react'
import styles from './CountStepper.module.css'
import Minus from '../icons/Minus'
import Plus from '../icons/Plus'

interface CountStepper {
  count: number
  minValue: number
  onMinus: (id?: string ) => void
  onPlus: (id?: string) => void
  id?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean,
}

function CountStepper({ count, minValue, id, error, onMinus, onPlus, onChange, onBlur }: CountStepper) {
  return (
    <div className={styles.countStepper}>
      <button 
        className={styles.minusButton} 
        disabled={count === minValue} 
        aria-disabled={count === minValue} 
        onClick={() => onMinus(id)} 
        type="button" >
        <Minus className={styles.icon} color='var(--red)'/>
      </button>
      <input 
        type='number'
        inputMode='numeric'
        pattern='\d*'
        value={count.toString()}
        min={minValue}
        className={error ? styles.countError : styles.count}
        onChange={onChange}
        onBlur={onBlur}
      />
      <button className={styles.plusButton} onClick={() => onPlus(id)} type="button">
        <Plus className={styles.icon} color='#fff'/>
      </button>
    </div>   
  )
}
export default CountStepper