export function getTaxFactor(taxCode: number) {
  switch (taxCode) {
  case 1:
    return 0.8
  case 2:
    return 1/1.12
  case 3:
    return 1/1.06
  default:
    return 0.8
  }
}