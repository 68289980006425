import { ExpandedCategory, Variant, VariantCategory } from "../types/Product"

export function getActiveCategoryValues(category: VariantCategory, variants: Variant[]) {
  return category.values.filter((categoryValue) => {
    return variants.some((variant) => {
      return variant.active === true && variant.values.some((variantValue) => variantValue.categoryValueId === categoryValue.id)
    })
  })
}

export function resetSelection(categoryIds: string[], currentSelection: ExpandedCategory[]) {
  const copy = [...currentSelection]
  categoryIds.forEach((categoryId) => {
    const index = copy.findIndex((category) => category.id === categoryId)
    if(index !== -1) {
      const reset = { ...copy[index], values: copy[index].values.map((v) => {
        return { ...v, selected: false, disabled: false }
      }) }
      copy[index] = { ...reset }
    }
  })
  return [...copy]
}

export function getSelectedVariantIdFromCategoryId(expandedCategories: ExpandedCategory[], categoryId: string) {
  const index = expandedCategories.findIndex(category => category.id === categoryId)
  return expandedCategories[index]?.values.find(value => value.selected === true)?.id ?? ''
}

export function getLevelFromCategoryId(variantCategories: VariantCategory[], categoryId: string) {
  return variantCategories.findIndex(category => category.id === categoryId)
}

export function findActive(variants: Variant[], variantsToTest: string[]) {
  return variants.filter(variant =>
    variantsToTest.every(test => variant.values.some(value => value.categoryValueId === test)) && variant.active
  )
}