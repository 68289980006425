import React from 'react'
import styles from '../CateringOrderItem.module.css'
import TextArea from '../TextArea'

interface Props {
  localStoreComment?: string
  originalStoreComment?: string
  onStoreCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void  
}

function UpdateStoreComment ({ localStoreComment, originalStoreComment, onStoreCommentChange }: Props) {
  return (
    <div className={styles.storeCommentContainer}>
      <h4 style={{ marginTop: '1rem' }} className={styles.orderHeading}>
        {originalStoreComment ? 'Ändra intern butikskommentar' : 'Skriv en intern butikskommentar'}
      </h4>
      <span className={styles.storeCommentHeading}>Intern butikskommentar</span>
      <span className={styles.storeCommentInfo}>Denna kommentar kommer enbart visas för er butiksmedarbetare och blir ej synlig ut mot kunden.</span>
      <TextArea
        placeholder={localStoreComment ? localStoreComment : 'Skriv en intern butikskommentar'} 
        value={localStoreComment as string} 
        setComment={(e: React.ChangeEvent<HTMLTextAreaElement>) => onStoreCommentChange(e)}
      />
    </div>
  )
}

export default UpdateStoreComment