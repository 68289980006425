import { emptyGuid } from "../constants"
import { OrderUpdateOperation } from "../types/Order"
import { OrderDifference } from "./isProductUpdated"

/**
 * Creates operations for updating item quantities and store comment
 * 
 * @param {OrderDifference[]} updatedProducts - An array of objects representing the differences between the current and new order states.
 * @param {string | null} storeComment - A string containing a comment to be added to the order, or null if no comment is to be added.
 * @returns {Promise<OrderUpdateOperation[]>} - An array of OrderUpdateOperation objects representing the operations to be performed on the order.
 */

const createEditOrderOperations = async (
  updatedProducts: OrderDifference[] | [],
  storeComment: string | null,
  updatedDeliveryDate: string | null,
  updatedTimeSlot: string | null,
  variantId: string | null,
  updatedCustomerName: string | null,
  updatedCustomerPhone: string | null,
  updatedCustomerEmail: string | null,
  productToAdd: { productId: string, variantId: string, quantity: number, orderItemComment: string } | null

): Promise<OrderUpdateOperation[]> => {
  let operations: OrderUpdateOperation[] = []

  // Loop through each updated amount on order item
  for (const update of updatedProducts) {
    // Calculate the total quantity from the update
    const quantity = update.ids.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
    // Calculate the change in quantity
    const change = quantity - update.newQuantity

    if (change === 0) break

    // If the change is negative, update the quantity of the first item
    if (change < 0) {
      const changeValue = update.ids[0].quantity + Math.abs(change)
      operations = [...operations, {
        requestOperationType: 'UpdateItemQuantity',
        value: changeValue.toString(),
        orderItemId: update.ids[0].id
      }]
      // If the change is positive, distribute the change across items
    } else if (change > 0) {
      let newChangeValue = change
      while (newChangeValue > 0) {
        for (const item of update.ids) {
          if (newChangeValue <= item.quantity) {
            operations = [...operations, {
              requestOperationType: 'UpdateItemQuantity',
              value: (item.quantity - newChangeValue).toString(),
              orderItemId: item.id
            }]
            newChangeValue = 0
            break
          } else {
            operations = [...operations, {
              requestOperationType: 'UpdateItemQuantity',
              value: (0).toString(),
              orderItemId: item.id
            }]
            newChangeValue -= item.quantity
          }
        }
      }
    }
  }

  // If there's a store comment or store has deleted comment, add it as an operation
  if (storeComment || storeComment === "") {
    operations = [...operations, {
      requestOperationType: 'UpdateStoreComment',
      value: storeComment,
    }]
  }

  // If date has been updated, add it as an operation
  if (updatedDeliveryDate) {
    operations = [...operations, {
      requestOperationType: 'UpdateDeliveryDate',
      value: updatedDeliveryDate,
    }]
  }

  // If time slot has been updated, add it as an operation
  if (updatedTimeSlot) {
    operations = [...operations, {
      requestOperationType: 'UpdateDeliveryTimeSlot',
      value: updatedTimeSlot,
    }]
  }

  // If variantId has been updated, distribute it across items
  if (variantId) {
    for (const update of updatedProducts) {
      for (const item of update.ids) {
        operations = [...operations, {
          requestOperationType: 'UpdateItemVariant',
          value: variantId,
          orderItemId: item.id
        }]
      }
    }
  }

  // If customer info has been updated, add it as an operation
  if (updatedCustomerName) {
    operations = [...operations, {
      requestOperationType: 'UpdateCustomerName',
      value: updatedCustomerName,
    }]
  }

  if (updatedCustomerPhone) {
    operations = [...operations, {
      requestOperationType: 'UpdateCustomerPhoneNumber',
      value: updatedCustomerPhone,
    }]
  }

  if (updatedCustomerEmail) {
    operations = [...operations, {
      requestOperationType: 'UpdateCustomerEmail',
      value: updatedCustomerEmail,
    }]
  }

  // If product has been added to order, add it as an operation
  if (productToAdd) {
    const productObj = {
      productId: productToAdd?.productId,
      variantId: productToAdd?.variantId === "" ? emptyGuid : productToAdd.variantId,
      quantity: productToAdd?.quantity,
      orderItemComment: productToAdd?.orderItemComment
    }
    const escapeJson = JSON.stringify(productObj)
    operations = [...operations, {
      requestOperationType: 'AddProduct',
      value: escapeJson,
    }]
  }
  return operations
}

export default createEditOrderOperations