import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux"
import { store } from './app/store'
import App from './app/routes'
import './custom.css'
import { appInsights } from './utils/applicationInsights'
import ErrorBoundary from './components/ErrorBoundary'

const rootElement = document.getElementById('root')

if(import.meta.env.VITE_ENV !== 'development') {
  appInsights.loadAppInsights()
}

if(rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  )
}