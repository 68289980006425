import React from 'react'
import styles from './Variants.module.css'
import { ExpandedCategory, Product, Variant, VariantCategory } from '../types/Product'
import { findActive, getActiveCategoryValues, getLevelFromCategoryId, getSelectedVariantIdFromCategoryId, resetSelection } from '../helpers/variantHelper'

interface Props {
  product: Product
  variantId?: string
  predefined: boolean
  onSelectVariant: (variant: Variant) => void
}

function Variants({ product, variantId, predefined, onSelectVariant }: Props) {
  const [expandedCategories, setExpandedCategories] = React.useState<ExpandedCategory[]>([])
  const [variantCategoryIds, setVariantCategoryIds] = React.useState<string[]>([])
  const [defaultVariantSelected, setDefaultVariantSelected] = React.useState<boolean>(false)
  const [selectedVariant, setSelectedVariant] = React.useState<Variant>()
  const { variantCategories, variants } = product

  const updateSelectedValue = ({ categoryId, valueId, selected, disabled } : {categoryId: string, valueId: string, selected: boolean, disabled: boolean}) => {
    if(expandedCategories.length > 0) {
      setExpandedCategories((prev: ExpandedCategory[]) => {
        const copy = [...prev]
        const categoryIndex = copy?.findIndex((category) => category.id === categoryId)
  
        if (categoryIndex !== -1) {
          copy[categoryIndex] = { ...copy[categoryIndex], values: copy[categoryIndex].values.map((val) => {
            if (val.id === valueId) {
              return { ...val, selected: selected, disabled: disabled }
            } else {
              return val
            }
          }) }
        }
        return [...copy]
      })
    }
  }

  React.useEffect(() => {
    // Check that at least one value is active in every category group, else remove the inactive value
    const categories = [...variantCategories as VariantCategory[]]
    categories.forEach((category) => {
      category.values = getActiveCategoryValues(category, variants as Variant[])
    })
  
    setExpandedCategories(categories.map((category) => {
      return { ...category, values: category.values.map((value) => {
        return { ...value, selected: false, disabled: false }
      }) }
    }))
    setVariantCategoryIds(categories.map((category) => {return category.id}))
  
  }, [variants, variantCategories])

  React.useEffect(() => {
    if(selectedVariant) {
      onSelectVariant(selectedVariant)
    }
  }, [onSelectVariant, selectedVariant])

  const findFirstActiveByValueId = React.useCallback(({ categoryId, valueId } : { categoryId: string, valueId: string }) => {
    setDefaultVariantSelected(true)
    const previousSelection = variantCategoryIds.map((category) => {
      return getSelectedVariantIdFromCategoryId(expandedCategories, category)
    })

    // Get previous selections. The previous selections should not be altered
    const levelOfSelection = getLevelFromCategoryId(variantCategories as VariantCategory[], categoryId)
    
    let selectedVariantIds: string[] = []
    const selectedVariantCategoryIds = variantCategoryIds.slice(0, levelOfSelection)
  
    selectedVariantCategoryIds && selectedVariantCategoryIds.forEach((previous) => {
      selectedVariantIds.push(getSelectedVariantIdFromCategoryId(expandedCategories, previous))
    })

    if (levelOfSelection === (variantCategories && variantCategories.length - 1)) {
      // Reset the previously selected value on the last level
      const previousLastSelected = selectedVariant?.values.find((value) => value.categoryId === categoryId)
      if (previousLastSelected) {
        updateSelectedValue({ categoryId: previousLastSelected.categoryId, valueId: previousLastSelected.categoryValueId, selected: false, disabled: false })
      }
      //Should be selected directly, since it does not alter anything below it
      updateSelectedValue({ categoryId, valueId, selected: true, disabled: false })
      const activeVariants = findActive(variants as Variant[], [...selectedVariantIds, valueId])
      if (activeVariants && activeVariants.length > 0) {
        setSelectedVariant(activeVariants[0])
      }
      return
    }
  
    // Reset the category level and set the selected one as selected
    if (variantCategories) {
      const resetCategories = resetSelection(variantCategoryIds.slice(levelOfSelection && levelOfSelection + 1, variantCategories.length), expandedCategories)
      setExpandedCategories(resetCategories)
    }
    const previousLastSelected = selectedVariant?.values.find((value) => value.categoryId === categoryId)
    if (previousLastSelected) {
      updateSelectedValue({ categoryId: previousLastSelected.categoryId, valueId: previousLastSelected.categoryValueId, selected: false, disabled: false })
    }
    updateSelectedValue({ categoryId, valueId, selected: true, disabled: false })
        
    // Add the selected variant to the array of variantIds
    selectedVariantIds.push(valueId)
  
    let currentLevel = levelOfSelection + 1
    let currentValueLevel = 0
    let levelCounter = 0
    let valueLevelCounter = 0
    const maxIterationsForLevel = 10
    const maxIterationsForValueLevel = 20
    let previousActive = false

    // Check if the previous selection with the new selected value is active
    const activeSelection = findActive(variants as Variant[], selectedVariantIds.concat(previousSelection.slice(currentLevel, previousSelection.length)))
    if (activeSelection && activeSelection.length > 0) {
      previousActive = true
    }
  
    //Find the first selectable value
    if (variantCategories) {
      while (currentLevel < variantCategories.length && levelCounter < maxIterationsForLevel) {
        const nextCategory = variantCategoryIds[currentLevel]
        const nextValueId = variantCategories?.find((cat) => cat.id === nextCategory)?.values[currentValueLevel]?.id ?? ''
        const currentValueLevelLength = variantCategories?.find((category) => category.id === nextCategory)?.values?.length
  
        selectedVariantIds.push(nextValueId !== undefined ? nextValueId : '')
    
        const result = findActive(variants as Variant[], selectedVariantIds)
    
        if (result && result.length > 0) {
          // can not be disabled, since at least one combination is active
          
          if(previousActive && nextValueId !== previousSelection[currentLevel]) {
            // if the previous selection is active and the current valueId is active, set the previous selection as the selected value
            selectedVariantIds = selectedVariantIds.filter((v) => v !== nextValueId)
            updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: false, disabled: false })
            updateSelectedValue({ categoryId: nextCategory, valueId: previousSelection[currentLevel], selected: true, disabled: false })
            selectedVariantIds.push(previousSelection[currentLevel])
          } else {
            updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: true, disabled: false })
          }
          
          // If there exists more values on the valueLevel, check remaining to find possible disabled
          while (currentValueLevelLength && currentValueLevel < currentValueLevelLength - 1 && valueLevelCounter < maxIterationsForValueLevel) {
            const variantsToTest = selectedVariantIds.filter((variant) => previousActive ? variant !== previousSelection[currentLevel] : variant !== nextValueId)
            const currentValueId = variantCategories.find((category) => category.id === nextCategory)?.values[currentValueLevel + 1].id
            currentValueId && variantsToTest.push(currentValueId)
            const result = findActive(variants as Variant[], variantsToTest)
            if (result && currentValueId && result.length === 0) {
              updateSelectedValue({ categoryId: nextCategory, valueId: currentValueId, selected: false, disabled: true })
            }
            // Continue with next value on the current level
            currentValueLevel++
            valueLevelCounter++
          }
    
          // Go to next level to find first active
          currentValueLevel = 0
          currentLevel++
        } else {
          updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: false, disabled: true })
          selectedVariantIds = selectedVariantIds.filter((variantId) => variantId !== nextValueId)
          currentValueLevel++
        }
        levelCounter++
      }
    }
    setSelectedVariant(findActive(variants as Variant[], selectedVariantIds)[0])
  }, [variants, variantCategories, variantCategoryIds, expandedCategories, selectedVariant?.values])

  const selectPredefinedVariant = React.useCallback(({ categoryId, valueId } : { categoryId: string, valueId: string }) => {
    setDefaultVariantSelected(true)
    // Get the predefined variant that should be selected
    const predefinedVariant = product.variants?.find((variant) => {
      return variant.id === variantId
    })

    // Level of selection is always 0 since we send in the first category, and only run this on the first render
    const levelOfSelection = 0
    
    let selectedVariantIds: string[] = []
    // Set the given predefined value on the first category as selected
    updateSelectedValue({ categoryId, valueId, selected: true, disabled: false })
        
    // Add the selected variant to the array of variantIds
    selectedVariantIds.push(valueId)
  
    let currentLevel = levelOfSelection + 1
    let currentValueLevel = 0
    let levelCounter = 0
    let valueLevelCounter = 0
    const maxIterationsForLevel = 10
    const maxIterationsForValueLevel = 20
  
    // Check the first value on the current level
    if (variantCategories) {
      while (currentLevel < variantCategories.length && levelCounter < maxIterationsForLevel) {
        const nextCategory = variantCategoryIds[currentLevel]
        const predefinedValue = predefinedVariant?.values.find((value) => value.categoryId === nextCategory)
        const nextValueId = variantCategories?.find((cat) => cat.id === nextCategory)?.values[currentValueLevel]?.id ?? ''
        const currentValueLevelLength = variantCategories?.find((category) => category.id === nextCategory)?.values?.length
  
        // If the value id is the preselected value id, set the value as selected and add to selectedVariantIds
        // Else check if the value has any active variant combinations, if not it should be set as disabled
        if (predefinedValue?.categoryValueId === nextValueId) {
          updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: true, disabled: false })
          selectedVariantIds.push(nextValueId !== undefined ? nextValueId : '')
        } else {
          const activeVariantCombinations = findActive(variants as Variant[], [...selectedVariantIds, nextValueId])
          if (activeVariantCombinations?.length === 0) {
            updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: false, disabled: true })
          }
        }
        const result = findActive(variants as Variant[], selectedVariantIds)
  
        // Check remaining values on the current level
        if (result && result.length > 0) {
          while (currentValueLevelLength && currentValueLevel < currentValueLevelLength - 1 && valueLevelCounter < maxIterationsForValueLevel) {
            // Filter out the predefined category value id if it exists
            const variantsToTest = selectedVariantIds.filter((variant) => variant !== predefinedValue?.categoryValueId)
            // Get the next variant value in the same category
            const currentValueId = variantCategories.find((category) => category.id === nextCategory)?.values[currentValueLevel + 1].id
            currentValueId && variantsToTest.push(currentValueId)
            // Check if there are active variant combinations with the variantsToTest
            const result = findActive(variants as Variant[], variantsToTest)
  
            if (currentValueId && result?.length === 0) {
              updateSelectedValue({ categoryId: nextCategory, valueId: currentValueId, selected: false, disabled: true })
            }
            if (currentValueId && currentValueId === predefinedValue?.categoryValueId) {
              updateSelectedValue({ categoryId: nextCategory, valueId: currentValueId, selected: true, disabled: false })
              selectedVariantIds.push(currentValueId !== undefined ? currentValueId : '')
            }
            // Continue with next value on the current level
            currentValueLevel++
            valueLevelCounter++
          }
    
          // Go to next level to find first active
          currentValueLevel = 0
          currentLevel++
        } else {
          updateSelectedValue({ categoryId: nextCategory, valueId: nextValueId, selected: false, disabled: true })
          selectedVariantIds = selectedVariantIds.filter((variantId) => variantId !== nextValueId)
          currentValueLevel++
        }
        levelCounter++
      }
    }
    setSelectedVariant(findActive(variants as Variant[], selectedVariantIds)[0])
  }, [variants, variantCategories, variantCategoryIds, expandedCategories, selectedVariant?.values])

  const getValue = React.useCallback((categoryId: string, valueId: string) => {
    const index = expandedCategories.findIndex((category) => category.id === categoryId)
    return expandedCategories[index]?.values.find((value) => value.id === valueId)
  }, [expandedCategories])

  const onSelect = React.useCallback(({ categoryId, valueId, predefined }: {categoryId: string, valueId: string, predefined: boolean}) => {
    // Do not try to find first active if the pressed value is currently selected
    if (getValue(categoryId, valueId)?.selected !== true && expandedCategories.length > 0) {
      predefined ? selectPredefinedVariant({ categoryId, valueId }) : findFirstActiveByValueId({ categoryId, valueId })
    }
  }, [expandedCategories, findFirstActiveByValueId, getValue])

  React.useEffect(() => {
    if (!defaultVariantSelected && variantCategories && variantCategories.length > 0) {
      
      if (predefined) {
        const predefinedVariant = product.variants?.find((variant) => {
          return variant.id === variantId
        })
        let minSortOrderObject = variantCategories[0]
  
        for (let i = 1; i < variantCategories.length; i++) {
          if (variantCategories[i].sortOrder < minSortOrderObject.sortOrder) {
            minSortOrderObject = variantCategories[i]
          }
        }
  
        // The first category is the one with the lowest sortOrder
        const firstCategory = predefinedVariant?.values.find((value) => {
          return value.categoryId === minSortOrderObject.id
        })
  
        if (predefinedVariant && predefinedVariant.values && predefinedVariant.values.length > 0) {
          onSelect({
            categoryId: firstCategory?.categoryId || '',
            valueId: firstCategory?.categoryValueId || '',
            predefined: predefined
          })
        }
      } else {
        const firstCategory = variantCategories[0]
        if (firstCategory && firstCategory.values && firstCategory.values.length > 0) {
          onSelect({
            categoryId: firstCategory?.id,
            valueId: firstCategory.values[0]?.id,
            predefined: predefined
          })
        }
      }  
    }
  }, [variantCategories, onSelect, defaultVariantSelected])
  
  return (
    <>
      {expandedCategories.map((category) => {
        return (
          <div key={category.id} className={styles.variant}>
            <h3 className={styles.label}>
              {category.name}
            </h3>
            <div className={styles.variants}>
              {category.values.map((value) => {
                return (
                  <div key={value.id}>
                    <button 
                      role='radio' 
                      disabled={getValue(category.id, value.id)?.disabled === true} 
                      onClick={() => onSelect({ categoryId: category.id, valueId: value.id, predefined: false })} 
                      className={getValue(category.id, value.id)?.selected === true ? styles.buttonSelected : styles.button} >
                      <span>{value.name}</span>
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Variants