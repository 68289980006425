import { emptyGuid } from "../constants"
import { OrderItem } from "../types/Order"

const groupCateringOrderItems = (orderItems: OrderItem[]) => {
  const groupedCateringOrderItems = orderItems.reduce((prev, current) => {
    // Create a unique key based on variantId + message or name + message
    const key =
      current.variantId !== emptyGuid && current.variantId + current.message in prev
        ? current.variantId + current.message
        : (current.variantId === emptyGuid || current.variantId === null) && current.name + current.message in prev
          ? current.name + current.message
          : current.variantId !== emptyGuid
            ? current.variantId + current.message
            : current.name + current.message

    // Accumulate quantities, priceGross, and vatSubTotal
    const newAmount = prev[key] ? prev[key].amount + current.quantity : current.quantity
    const newPriceGross = prev[key]
      ? prev[key].priceGross + (current.quantity > 0 ? current.priceGross : 0)
      : current.quantity > 0
        ? current.priceGross
        : 0
    const newVatSubTotal = prev[key]
      ? prev[key].vatSubTotal + (current.quantity > 0 ? current.vatSubTotal : 0)
      : current.quantity > 0
        ? current.vatSubTotal
        : 0

    return {
      ...prev,
      [key]: {
        ...current,
        amount: newAmount,
        priceGross: newPriceGross,
        vatSubTotal: newVatSubTotal,
        ids: [...(prev[key]?.ids || []), { id: current.id, quantity: current.quantity }],
      },
    }
  }, {} as { [key: string]: OrderItem })

  // Final pass to adjust priceGross and vatSubTotal with total quantity
  const groupedItems = Object.keys(groupedCateringOrderItems).map((key) => {
    const item = groupedCateringOrderItems[key]
    const finalPriceGross = item.amount > 0 ? item.priceGross / item.amount : 0
    const finalVatSubTotal = item.amount > 0 ? item.vatSubTotal / item.amount : 0
    return {
      ...item,
      priceGross: finalPriceGross,
      vatSubTotal: finalVatSubTotal,
    }
  })

  return groupedItems
}

export default groupCateringOrderItems
