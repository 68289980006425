import React from 'react'

type IconT = {
  className?: string
  color?: string
}

function Minus({ className, color = 'currentColor' }: IconT) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.8258 11.25H6.74982C6.67932 11.25 6.61407 11.2897 6.58257 11.3535L6.02007 12.4785C5.95782 12.603 6.04782 12.75 6.18732 12.75H17.8258C17.9338 12.75 18.0201 12.663 18.0201 12.5558V11.4435C18.0201 11.337 17.9338 11.25 17.8258 11.25Z" fill={color}/>
    </svg>

  )
}

export default Minus