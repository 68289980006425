import React from 'react'
import styles from '../CateringOrderItem.module.css'
import Input from '../Input'
import Banner from '../Banner'
import { getFormattedPrice } from '../../helpers/formatPrice'
import { OrderUpdateError } from '../../types/Order'

interface Props {
  customerName: string
  customerEmail: string
  customerPhone: string
  isCardPayment: boolean
  originalTotalPrice: number
  error: OrderUpdateError
  onInputChange: (inputField: string, value: string) => void
  onEmailValidation: (isValid: boolean) => void
  onNameValidation: (isValid: boolean) => void
  onPhoneValidation: (isValid: boolean) => void
}

function UpdateCustomerDetails ({ customerName, customerEmail, customerPhone, isCardPayment, originalTotalPrice, error, onInputChange, onEmailValidation, onNameValidation, onPhoneValidation }: Props) {
  const [validName, setValidName] = React.useState<boolean>(true)
  const [validPhone, setValidPhone] = React.useState<boolean>(true)
  const [validEmail, setValidEmail] = React.useState<boolean>(true)

  const isValidName = (value: string) => {
    const isValid = value.length > 0
    setValidName(isValid)
    onNameValidation(isValid)
  }

  const isValidPhone = (value: string) => {
    const isValid = value.length >= 10 && /^[0-9\s+-]*$/.test(value)
    setValidPhone(isValid)
    onPhoneValidation(isValid)
  }

  const isValidEmail = (value: string) => {
    const regExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}))$/
    const isValid = regExp.test(value)
    setValidEmail(isValid)
    onEmailValidation(isValid)
  }

  const handleChange = (fieldToUpdate: string, e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(fieldToUpdate, e.target.value)
  }

  return (
    <>
      <div className={styles.bannerWrapper}>
        {isCardPayment && (
          <Banner
            theme='info'>
            <span> Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade orderbeloppet på {getFormattedPrice(originalTotalPrice)} </span>
          </Banner>
        )}
        {error.isError && (
          <Banner
            theme='warning'>
            <span>{error.errorText}</span> 
          </Banner>
        )}
      </div>
      <div className={styles.updateCustomerWrapper}>
        <h4 className={styles.updateHeading}>Ändra kunduppgifter</h4>
        <div className={styles.inputWrapper}>
          <Input 
            value={customerName} 
            onBlur={() => isValidName(customerName)} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('name', e)} 
            error={!validName} 
            errorText='Ange ett namn' 
            type='text' 
            id='namn' 
            placeholder='Namn'
            autoComplete='name' 
            required={true} />
          <Input 
            value={customerPhone} 
            onBlur={() => isValidPhone(customerPhone)} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('phone', e)} 
            error={!validPhone} 
            errorText='Ange ett giltigt telefonnummer' 
            type='tel' 
            id='telefonnummer' 
            placeholder='Telefonnummer' 
            autoComplete='tel' 
            inputMode='numeric' 
            required={true} />
          <Input 
            value={customerEmail} 
            onBlur={() => isValidEmail(customerEmail)} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('email', e)} 
            error={!validEmail} 
            errorText='Ange en giltig mejladress (t.e.x namn@exempel.se)' 
            type='text' 
            id='mejladress' 
            placeholder='Mejladress' 
            autoComplete='email' 
            required={true} />
        </div>
      </div>
    </>
  )
}

export default UpdateCustomerDetails