import React from 'react'
import { formatVariant } from "../helpers/formatVariant"
import styles from "./CateringItemDetail.module.css"
import ReadyMealIcon from "../icons/ReadyMeal"
import { getFormattedPrice } from "../helpers/formatPrice"
import { OrderChange, OrderHistoryOperation, OrderItem } from "../types/Order"
import EditIcon from '../icons/Edit'

interface Props {
  item: OrderItem
  orderHistory?: OrderChange[]
  editMode?: boolean
  editProduct?: (item: OrderItem) => void
}

function CateringItemDetail({ item, orderHistory, editMode, editProduct }: Props) {
  const { name, imageUrl, amount, priceGross, variantName, message } = item || {}
  const variants = formatVariant(variantName)
  const [localAmount, setLocalAmount] = React.useState<number | ''>('')
  const [oldAmount, setOldAmount] = React.useState<string>('')
  
  React.useEffect(() => {
    setLocalAmount(item.amount)
  }, [item.amount, editMode])

  React.useEffect(() => {
    if (orderHistory) {
      const foundOperation = orderHistory.reduce<OrderHistoryOperation | null>((acc, history) => {
        if (acc) return acc
        return history.operations.findLast(operation => 
          item.ids?.some(id => id.id === operation.orderItemId && operation.operationType === 'UpdateItemQuantity') || null
        ) || null
      }, null)
     
      if (foundOperation) {
        setOldAmount(foundOperation.calculatedOldValue as string)
      }
    }
  }, [orderHistory])
   
  return (
    <div className={styles.item}>
      {imageUrl ? (
        <div className={styles.image}>
          <img className={styles.img} alt="" src={imageUrl} />
        </div>
      ) : (
        <div className={styles.placeholderImage}>
          <ReadyMealIcon className={styles.readyMealIcon} />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.itemDetails}>
          <h3 className={styles.name}>{name}</h3>
          {variants && variants.length > 0 ? (
            <ul>
              {variants.map((variant: string, index: number) => (
                <li className={styles.variantRow} key={index}>
                  {variant}
                </li>
              ))}
            </ul>
          ) : null}
          {message ? (
            <p>
              <span className={styles.messageLabel}>
                Meddelande till butik:
              </span>{" "}
              {message}
            </p>
          ) : null}
        </div>
        <div className={styles.priceDetailsContainer}>
          <div className={styles.priceDetails}>
            <span className={styles.oldAmount}>{oldAmount ? `${oldAmount} st` : ''}</span>
            <span>{amount} st</span>
            <span className={styles.pricePerItem}>{getFormattedPrice(priceGross)}/st</span>
            <span className={styles.price}>
              {localAmount && getFormattedPrice(Math.round(priceGross * localAmount * 100) * 0.01)}
              {localAmount === '' && '0 kr'}
            </span> 
          </div>
          {editMode &&
          <div className={styles.editProduct}>
            <button className={styles.editProductButton}
              onClick={() => { editProduct && editProduct(item) }}>
              <span className={styles.iconWrapper}>
                <EditIcon color='#cf2e05' className={styles.editIcon} />
              </span>
              Ändra i produkt</button>
          </div>
          } 
        </div>
      </div>
    </div>
  )
}
export default CateringItemDetail
